<template>
    <div class="ourservices">
        <div class="my-title">
            <van-divider>新闻资讯</van-divider>
            <div class="my-sub-title">Our News</div>
        </div>
        <div class="container">
            <div class="each-item  each-item1 pc-nav-hidden">
                <div class="item-inner">
                    <div class="image-warp">
                        <div class="image-inner">
                            <img src="../../assets/images/new.jpg"/>
                        </div>
                    </div>
                    <div class="text-inner">
                        <div class="">黑马新支付会议</div>
                    </div>
                </div>
            </div>
            <div class="each-item">
                <div class="item">
                    <div class="item-inner">
                        <div class="deta-wrap">
                            <div class="bg-deta">09</div>
                            <div class="line"></div>
                            <div class="small-deta">2020.01</div>
                        </div>
                        <div class="text-wrap">
                            <div class="title">企业赞助</div>
                            <div class="des">创云数据技术有限公司赞助了第三届陕西支付圈行业年会，公司领导在会上发表了致辞</div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <div class="deta-wrap">
                            <div class="bg-deta">20</div>
                            <div class="line"></div>
                            <div class="small-deta">2019.09</div>
                        </div>
                        <div class="text-wrap">
                            <div class="title">企业福利</div>
                            <div class="des">在山西麦古商务服务有限公司建立以来公司每逢佳节均会对企业员工进行人文关怀，发放与节日相关的礼品，充分体现了企业对员工的重视程度</div>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="item-inner">
                        <div class="deta-wrap">
                            <div class="bg-deta">15</div>
                            <div class="line"></div>
                            <div class="small-deta">2019.10</div>
                        </div>
                        <div class="text-wrap">
                            <div class="title">百日誓师大会</div>
                            <div class="des">公司组织了百日誓师大会，在会上公司领导发表了对公司前景的展望，并带领公司全体员工进行了宣誓，使公司凝聚力上升到了一个新的层次</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "our-news",
        data() {
            return {}
        }
    }
</script>

<style scoped lang="less">
    .ourservices {
        background-color: #f6f6f6;
        padding: 70px 0 40px;
        .container {
            padding: 0 5% 80px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            .each-item:first-child {
                width: 64%;
                margin-left: 1.33%;
                cursor: pointer;
                overflow: hidden;
                img {
                    display: block;
                    height: 100%;
                }
            }
            .each-item1 {
                position: relative;
                .text-inner {
                    position: absolute;
                    width: 100%;
                    height: 80px;
                    padding: 0 30px;
                    box-sizing: border-box;
                    line-height: 80px;
                    bottom: 0;
                    z-index: 10;
                    background-color: rgba(0,0,0,0.5);
                    color:#fff;
                    font-size: 18px;
                    transform: translate3d(0, 80px, 0);
                    transition: all .6s;
                }
            }
            .each-item1:hover .text-inner{
                transform:none;
            }
            .each-item:last-child {
                width: 32%;
                margin-left: 1.33%;
                display: flex;
                flex-direction: column;
                .item {
                    flex: 1;
                    background-color: #fff;
                    margin-bottom: 10px;
                    position: relative;
                    cursor: pointer;
                    color: #333;
                    min-height: 150px;
                    .item-inner {
                        position: absolute;
                        height: 100%;
                        z-index: 1;
                        padding: 30px;
                        box-sizing: border-box;
                        display: flex;
                        .deta-wrap {
                            border-right: 1px solid #e7e7e7;
                            width: 80px;
                            min-width: 80px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            .bg-deta {
                                font-size: 36px;
                                font-weight: bold;
                                color: #999;
                            }
                            .line {
                                width: 20px;
                                height: 2px;
                                margin: 15px 0;
                                background-color: #e7e7e7;
                            }
                            .small-deta {
                                font-size: 12px;
                                color: #666;
                            }
                        }
                        .text-wrap {
                            flex: auto;
                            overflow-y: auto;
                            padding-left: 20px;
                            .title {
                                margin-top: -7px;
                                height: 40px;
                                line-height: 32px;
                                font-size: 18px;
                                overflow: hidden;
                            }
                            .des {
                                font-size: 12px;
                            }
                        }
                    }
                }
                .item:last-child {
                    margin-bottom: 0px;
                }
                .item:after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #fff;
                    transition: all .6s;
                }
                .item:hover:after {
                    transform: scale(1.025);
                    box-shadow: 0 15px 15px rgba(0, 0, 0, .1);
                }
            }
        }
    }

    @media (max-width: 1330px) {
        .ourservices {
            .container {
                padding: 0 4.5% 80px;
            }
        }
    }

    @media (min-width: 1570px) {
        .ourservices {
            .container {
                .each-item:first-child {
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

    @media (max-width: 991px) {
        .ourservices {
            padding: 40px 0 20px;
            .container {
                .each-item:last-child {
                    width: 100%;
                    .item {
                        .item-inner {
                            .deta-wrap {
                                width: 11rem;
                            }
                        }
                    }
                }

            }
        }
    }
</style>