import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./views/home/index.vue";
import About from "./views/about/index.vue";
import Business from "./views/business/index.vue";
import News from "./views/news/index.vue";
import Contant from "./views/contant/index.vue";
import User from "./views/user/index.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/about",
        name: "about",
        component: About
    },
    {
        path: "/business",
        name: 'business',
        component: Business
    },
    {
        path: "/news",
        name: 'news',
        component: News
    },
    {
        path: "/contant",
        name: 'contant',
        component: Contant
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes
});
router.afterEach((to, from) => {
    window.scrollTo(0, 0)
})

export default router;
