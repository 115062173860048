<template>
    <div class="contant">
        <div class="fixed-contact-wrap" :class="showcontant?'show':''">
            <div class="item-list">
                <div class="ipone">
                    <a rel="nofollow" target="_blank" href="tel:177 1004 9798">
                        <div class="fontcolor">177 1004 9798</div>
                        <i class="iconfont fontcolor icon-telephone"></i>
                    </a>
                </div>
                <div class="back-top" @click="gotop">
                    <div class="fontcolor">返回顶部</div>
                    <i class="iconfont fontcolor icon-tubiaozhizuo-"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "contant",
        props:{
            showcontant:{
                type:Boolean,
                default:false,
            }
        },
        data(){
            return{
            }
        },
        methods:{
            gotop(){
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
        }
    }
</script>

<style scoped lang="less">
    .fixed-contact-wrap {
        z-index: 999;
        position: fixed;
        right: 30px;
        top: 75%;
        width: 60px;
        margin-top: -90px;
        overflow: hidden;
        border-radius: 30px;
        opacity: 0;
        transition: all .4s;
        transform: translateX(95px);
        -webkit-transform: translateX(95px);
        -moz-transform: translateX(95px);
        background-color: #222;
        cursor: pointer;
        .item-list {
            width: 60px;
            height: 120px;
            float: right;
            .ipone,
            .back-top {
                width: inherit;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 60px;
                position: relative;
                div{
                    position: absolute;
                    top:20px;
                    bottom:0;
                    left:-130px;
                    z-index: 1000;
                    width: 130px;
                    white-space: nowrap;
                    color:#fff;
                }
            }
            .ipone:hover .fontcolor,
            .back-top:hover .fontcolor{
                color:#ccc;
            }
            .iconfont {
                color: #fff;
                font-size: 20px;
            }
        }
    }

    .fixed-contact-wrap:hover {
        width: 215px;
    }
    .show{
        transform: none;
        opacity: 1;
    }
</style>