<template>
    <div>
        <!--pc顶部导航-->
        <nav-bar class="pc-nav-hidden" :class="activeindex != 0?'nav-active':''"></nav-bar>
        <!--移动端导航-->
        <move-nav-bar class="move-nav-hidden"></move-nav-bar>
        <!--联系我们-->
        <contant class="sm-hidden" :showcontant="showcontant"></contant>
        <swiper class="banner-container" :options="swiperOption" :style="'height:' + fullHeight +'px'" ref="mySwiper">
            <swiper-slide class="slide-one">
                <div class="image-wrap">
                    <div class="image-inner" :style="{backgroundImage: 'url(' + banner1 + ')'}"></div>
                </div>
                <div class="banner1">
                    <!--大标题-->
                    <div class="banner-title" :class="activebanner==0?'banner-title-active':''">打造属于你的联盟APP，你的联盟你做主
                    </div>
                    <!--进度条-->
                    <div class="subtitle-line">
                        <div class="subtitle-line-timer "
                             :class="activebanner==0?'subtitle-line-timer-active':''"></div>
                    </div>
                    <!--子标题-->
                    <div class="banner-sub-title" :class="activebanner==0?'banner-sub-title-active':''">致力于支付行业产业升级
                    </div>
                </div>
                <!--下一页提示箭头-->
                <div class="arrows" @click="nextpage">
                    <i class="iconfont icon-shuangxiajiantou-"></i>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="image-wrap">
                    <div class="image-inner" :style="{backgroundImage: 'url(' + banner2 + ')'}"></div>
                </div>
                <div class="banner2">
                    <!--大标题-->
                    <div class="banner-title" :class="activebanner==1?'banner-title-active':''">
                        <div class="item-wrap">
                            <div>定制功能：免费开发</div>
                            <div>速度极快：原生开发1天上线</div>
                            <div>战略合作：技术入股 长久共赢</div>
                            <div>专一专注：我们不做POS业务 只做POS系统</div>
                        </div>
                        <div class="item-wrap">
                            <div>售后服务：7X24在线相应</div>
                            <div>数据安全：金融级数据加密</div>
                            <div>增值服务：信用卡、积分兑换等</div>
                            <div>经验丰富：八年系统开发，上百家系统运营实力</div>
                        </div>
                    </div>
                    <!--进度条-->
                    <div class="subtitle-line">
                        <div class="subtitle-line-timer "
                             :class="activebanner==1?'subtitle-line-timer-active':''"></div>
                    </div>
                    <!--子标题-->
                    <div class="banner-sub-title" :class="activebanner==1?'banner-sub-title-active':''">
                        平台化：集合所有联盟功能，支持所有POS品牌
                    </div>
                </div>
                <!--下一页提示箭头-->
                <div class="arrows" @click="nextpage">
                    <i class="iconfont icon-shuangxiajiantou-"></i>
                </div>
            </swiper-slide>
            <swiper-slide>
                <div class="image-wrap">
                    <div class="image-inner" :style="{backgroundImage: 'url(' + banner3 + ')'}"></div>
                </div>
                <div class="banner3">
                    <!--大标题-->
                    <div class="banner-title" :class="activebanner==2?'banner-title-active':''">
                        <div class="item-wrap">
                            <div>成为最有价值的技术服务公司</div>
                            <div>用技术让社会更高效，生活更美好</div>
                            <div>求实 · 严谨 · 高效 · 创新 · 分享</div>
                        </div>

                    </div>
                    <!--进度条-->
                    <div class="subtitle-line">
                        <div class="subtitle-line-timer "
                             :class="activebanner==2?'subtitle-line-timer-active':''"></div>
                    </div>
                    <!--子标题-->
                    <div class="banner-sub-title" :class="activebanner==2?'banner-sub-title-active':''">
                        企业文化
                    </div>
                </div>
                <!--下一页提示箭头-->
                <div class="arrows" @click="nextpage">
                    <i class="iconfont icon-shuangxiajiantou-"></i>
                </div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-h" slot="pagination"></div>
        </swiper>
        <!--行业痛点-->
        <our-services></our-services>
        <!--产品展示-->
        <our-works></our-works>
        <!--数据展示-->
        <data-number :shownumber="shownumber"></data-number>
        <!--合作平台-->
        <our-customers></our-customers>
        <!--新闻资讯-->
        <!-- <our-news></our-news> -->
        <!--版权栏-->
        <copyright></copyright>
    </div>
</template>

<script>
    import NavBar from "../../components/navBar";
    import MoveNavBar from "../../components/moveNavBar";
    import Contant from "../../components/contant";
    import OurServices from "./ourServices";
    import OurWorks from "./ourWorks";
    import DataNumber from "./dataNumber";
    import OurCustomers from "./ourCustomers";
    import OurNews from "./ourNews";
    import Copyright from "../../components/copyright";

    export default {
        components: {
            Copyright,
            OurNews,
            OurCustomers,
            DataNumber,
            OurWorks,
            OurServices,
            Contant,
            MoveNavBar,
            NavBar
        },
        name: "index",
        data() {
            let that = this;
            return {
                banner1: require('../../assets/images/index-banner1.png'),
                banner2: require('../../assets/images/index-banner2.png'),
                banner3: require('../../assets/images/index-banner3.png'),
                activeindex: 0,
                activebanner: 0,
                activebannertemp: 0,    //记录当前轮播展示变量
                showcontant: false,
                shownumber:true,
                fullHeight: document.documentElement.clientHeight,
                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination-h',
                    },
                    autoplay:true,
                    delay:5000,
                    on: {
                        transitionEnd: function (swiper) {
                            console.log(this.activeIndex)
                            that.activebanner = this.activeIndex
                            that.activebannertemp = this.activeIndex
                        }
                    }
                }
            }
        },
        created() {
            // this.getInfoData()
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    this.fullHeight = window.fullHeight
                })()
            }
            window.addEventListener('scroll', this.handleScroll, true)
        },
        computed: {
            swiper() {
                return this.$refs.mySwiper2.swiper
            }
        },
        methods: {
            //用户信息及签到状态
            async getInfoData() {
                this.info = await Service.getInfo()
                if (!this.info.id) {
                    this.$router.push({name: 'open'})
                } else {
                    this.showpage = true
                }
                if (this.info.signIn.length < 7) {
                    let num = 7 - this.info.signIn.length
                    for (var i = 0; i < num; i++) {
                        this.info.signIn.push(0)
                    }
                }
                // this.$toast({
                //     message: '自定义图标',
                //     icon: 'like-o'
                // });
            },
            gomap() {
                this.$router.push({path: '/about'})
            },
            nextpage() {
                window.scrollTo({
                    top: this.fullHeight,
                    behavior: "smooth"
                });
            },
            handleScroll(e) {
                var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
                var screen = document.documentElement.clientWidth;
                if (scrollTop > 120) {
                    this.activeindex = -1
                } else if (scrollTop < 50) {
                    this.activeindex = 0
                }
                if (scrollTop > 300) {
                    this.activebanner = -1
                    this.showcontant = true
                } else if (scrollTop < 150) {
                    this.activebanner = this.activebannertemp
                    this.showcontant = false
                }
                if(screen <991){
                    if(scrollTop >1100){
                        this.shownumber = true
                    }else{
                        this.shownumber = false
                    }
                }else{
                    if(scrollTop >1800){
                        this.shownumber = true
                    }else{
                        this.shownumber = false
                    }
                }

            }
        }
    }
</script>

<style scoped lang="less">
    /*banner背景图*/
    .banner-container {
        .image-wrap {
            overflow-x: hidden;
            position: absolute;
            width: 100%;
            height: 100%;
            .image-inner {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }
    }

    /*第一个banner*/
    .banner1 {
        position: relative;
        width: 80%;
        min-height: 200px;
        z-index: 100;
        top: 45%;
        right: 0;
        left: 0;
        margin: auto;
        .banner-title {
            font-size: 36px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            display: block;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(-150%, 0, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
        }
        .banner-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .banner-sub-title {
            font-size: 20px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 110px;
            display: block;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(0, 150%, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
        }
        .banner-sub-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        @media (max-width: 1200px) {
            .banner-title {
                font-size: 30px;
            }

            .banner-sub-title {
                font-size: 20px;
            }
        }
        .subtitle-line {
            position: absolute;
            bottom: 0;
            top: 90px;
            width: 65%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 1px;
            background-color: #999;
            content: '';
            .subtitle-line-timer {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                display: block;
                height: 100%;
                -webkit-transform-origin: left;
                -ms-transform-origin: left;
                transform-origin: left;
            }
            .subtitle-line-timer-active {
                animation: linewidth 16s infinite;
                -moz-animation: linewidth 16s infinite;
                -webkit-animation: linewidth 16s infinite;
                -o-animation: linewidth 16s infinite;
                animation-delay: .5s;
                -webkit-animation-delay: .5s;
            }
            @keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-moz-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-webkit-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-o-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
        }
    }
    /*第二个banner*/
    .banner2 {
        position: relative;
        width: 80%;
        z-index: 100;
        top: 39%;
        right: 0;
        left: 0;
        margin: auto;
        .banner-title {
            font-size: 26px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(0, 150%, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
            display: flex;
            justify-content: space-between;
            .item-wrap{
                text-align: left;
               div{
                  margin-bottom: 10px;
               }
            }
            .item-wrap:nth-child(1){
                margin-right: 20px;
            }
        }
        .banner-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .banner-sub-title {
            font-size: 20px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 240px;
            display: block;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(0, 150%, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
        }
        .banner-sub-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .subtitle-line {
            position: absolute;
            bottom: 0;
            top: 200px;
            width: 65%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 1px;
            background-color: #999;
            content: '';
            .subtitle-line-timer {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                display: block;
                height: 100%;
                -webkit-transform-origin: left;
                -ms-transform-origin: left;
                transform-origin: left;
            }
            .subtitle-line-timer-active {
                animation: linewidth 16s infinite;
                -moz-animation: linewidth 16s infinite;
                -webkit-animation: linewidth 16s infinite;
                -o-animation: linewidth 16s infinite;
                animation-delay: .5s;
                -webkit-animation-delay: .5s;
            }
            @keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-moz-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-webkit-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-o-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
        }
    }
    /*第三个banner*/
    .banner3 {
        position: relative;
        width: 80%;
        min-height: 200px;
        z-index: 100;
        top: 45%;
        right: 0;
        left: 0;
        margin: auto;
        .banner-title {
            font-size: 26px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 0;
            display: block;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(150%, 0, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
            .item-wrap{
                div{
                    margin-bottom: 10px;
                }
            }
        }
        .banner-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .banner-sub-title {
            font-size: 20px;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 220px;
            display: block;
            overflow: hidden;
            opacity: 0;
            transform: translate3d(0, 150%, 0);
            transition: all .2s;
            transition-delay: 0s;
            color: #fff;
        }
        .banner-sub-title-active {
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .subtitle-line {
            position: absolute;
            bottom: 0;
            top: 170px;
            width: 65%;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 1px;
            background-color: #999;
            content: '';
            .subtitle-line-timer {
                position: absolute;
                top: 0;
                left: 0;
                background-color: #fff;
                display: block;
                height: 100%;
                -webkit-transform-origin: left;
                -ms-transform-origin: left;
                transform-origin: left;
            }
            .subtitle-line-timer-active {
                animation: linewidth 16s infinite;
                -moz-animation: linewidth 16s infinite;
                -webkit-animation: linewidth 16s infinite;
                -o-animation: linewidth 16s infinite;
                animation-delay: .5s;
                -webkit-animation-delay: .5s;
            }
            @keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-moz-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-webkit-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }

            @-o-keyframes linewidth {
                from {
                    width: 0;
                }
                to {
                    width: 100%;
                }
            }
        }
    }

    /*下一页箭头样式*/
    .arrows {
        position: absolute;
        width: 80px;
        height: 80px;
        bottom: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: #fff;
        cursor: pointer;
        text-align: center;
        .iconfont {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            font-size: 30px;
            animation: downico 1.5s ease-out infinite;
        }
    }

    @keyframes downico {
        0% {
            top: 0;
            opacity: 1;
        }
        35% {
            top: 50%;
            opacity: 0;
        }
        65% {
            top: -50%;
            opacity: 0;
        }
        100% {
            top: 0;
            opacity: 1;
        }
    }

    @media (max-width:1200px){
        .banner2{
            .banner-title{
                font-size: 18px;
                .item-wrap div{margin-bottom: 0.5rem}
            }
        }
    }
    @media (max-width:991px){
        .banner1{
            .banner-title{font-size: 1.5rem;}
            .banner-sub-title{font-size: 1rem;}
        }
        .banner2{
            top:25%;
            .banner-title{
                text-align: center;
                font-size: 1.1rem;
                flex-direction: column;
                .item-wrap div{margin-bottom: 0.5rem}
            }
            .banner-sub-title{font-size: 1rem;top:21rem;}
            .subtitle-line{
                top:20rem;
            }
        }
        .banner3{
            top:40%;
            .banner-title{
                font-size: 1.1rem;
                .item-wrap div{margin-bottom: 0.5rem}
            }
            .banner-sub-title{font-size: 1rem;top:8rem;}
            .subtitle-line{
                top:7rem;
            }
        }
    }
</style>