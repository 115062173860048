<template>
  <div class="copyright">
    <div class="top-copyright">
      <div class="each-item">
        <div class="title">地址</div>
        <div class="address">
          <i class="iconfont icon-dizhi"></i>
          <div class="des">
            太原市小店区坞城路52号1幢1909室
          </div>
        </div>
      </div>
      <div class="each-item">
        <div class="title">联系电话</div>
        <div class="address">
          <i class="iconfont icon-telephone"></i>
          <div class="des">
            <a href="tel:177 1004 9798">177 1004 9798</a>
          </div>
        </div>
      </div>
      <div class="each-item">
        <div class="title">邮箱</div>
        <div class="address">
          <i class="iconfont icon-youxiang2"></i>
          <div class="des">sen202212@163.com</div>
        </div>
      </div>
      <!-- <div class="each-item">
				<div class="title">微信公众号</div>
				<div class="address">
					<img :src="qrcode" />
				</div>
			</div> -->
    </div>
    <div class="bottom-copyright">
      <span class="en">©2019-2020</span> 山西麦古商务服务有限公司
      <span class="en">ALL RIGHTS RESERVED.</span>
      <a rel="nofollow" href="https://beian.miit.gov.cn/" target="_blank">晋ICP备2024034168号-1</a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'copyright',
    data() {
      return {
        qrcode: require('../assets/images/qr-code.jpg')
      };
    },
    methods: {}
  };
</script>

<style scoped lang="less">
  .copyright {
    position: relative;
    background-color: #111;
    overflow: hidden;
    .top-copyright {
      padding: 60px 0;
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .each-item {
        width: 25%;
        height: 150px;
        margin: 0 25px;
        color: #fff;
        margin-bottom: 20px;
        .title {
          height: 50px;
          margin-bottom: 15px;
          font-size: 14px;
          border-bottom: 1px solid #282828;
          display: flex;
          align-items: center;
        }
        .address {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 15px;
          .iconfont {
            margin-right: 10px;
            color: #fff;
            font-size: 20px;
          }
          a {
            color: rgba(255, 255, 255, 0.5);
          }
          img {
            width: 82px;
            height: 82px;
          }
        }
      }
    }
    .bottom-copyright {
      a {
        color: rgba(255, 255, 255, 0.2);
      }
      a:hover {
        color: red;
      }
      width: 90%;
      margin: 0 auto 20px;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.2);
      text-align: center;
    }
  }
  @media (max-width: 991px) {
    .copyright {
      .top-copyright {
        flex-wrap: wrap;
        .each-item {
          width: 100%;
        }
      }
    }
  }
</style>
