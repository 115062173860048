import Vue from "vue";
Vue.directive('countRunning',{
    bind(el,binding,vnode,oldVnode){
        var timer = null;
        const targetDomCount = el.getAttribute('data-target');
        let nowCount = parseInt(el.innerHTML);
        var addcunt =parseInt(targetDomCount/50 +1)
        timer = setInterval(()=>{
            if(nowCount < targetDomCount){
                nowCount +=addcunt;
            }else {
                clearInterval(timer);
                timer = null;
            }
            el.innerHTML = nowCount;
        },80)
    }
});