<template>
    <div class="my-swiper">
        <!--移动端导航-->
        <move-nav-bar class="move-nav-hidden"></move-nav-bar>
        <swiper :options="swiperOption">
            <swiper-slide class="slide-item slide-item1">
                <!--pc顶部导航-->
                <nav-bar class="pc-nav-hidden" :class="activeindex != 0?'nav-active':''"></nav-bar>
                <!--顶部导航 end-->
                <!--背景图-->
                <div class="image-wrap">
                    <div class="image-inner">
                        <img src="../../assets/images/service_bg1.jpg" alt="山西麦古商务服务有限公司"/>
                    </div>
                </div>
                <!--背景图 end-->
                <div class="wrap" :class="activeindex == 0?'wrap-active':''">
                    <div class="wrap-inner">
                        <div class="line"></div>
                        <div class="des des1">
                            代理政策不透明；容易扣押分润；分润不透明，即使少发可能也不知道；传统招商困难；人员成本太高。
                        </div>
                    </div>
                </div>
                <!--下一页提示箭头-->
                <div class="arrows">
                    <i class="iconfont icon-shuangxiajiantou-"></i>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-item slide-item2">
                <div class="image-wrap">
                    <div class="image-inner">
                        <img src="../../assets/images/service_bg2.jpg" alt="山西麦古商务服务有限公司"/>
                    </div>
                </div>
                <div class="wrap" :class="activeindex == 1?'wrap-active':''">
                    <div class="wrap-inner">
                        <div class="line"></div>
                        <div class="des des1">
                            机具管理混乱；财务压力大；结算不透明；无法个性化开展业务；无法随时提现；团队管理混乱。
                        </div>
                    </div>
                </div>
                <!--下一页提示箭头-->
                <div class="arrows">
                    <i class="iconfont icon-shuangxiajiantou-"></i>
                </div>
            </swiper-slide>
            <swiper-slide class="slide-item slide-item3">
                <swiper :options="swiperOption2"  id="swiper-container2">
                    <swiper-slide >
                        <div class="slide-item3-1">
                            <div class="image-wrap">
                                <div class="image-inner">
                                    <img src="../../assets/images/service_bg3.png" alt="山西麦古商务服务有限公司"/>
                                </div>
                            </div>
                            <div class="wrap" :class="activeindex == 2?'wrap-active':''">
                                <div class="wrap-inner">
                                    <div class="line"></div>
                                    <div class="des des1">
                                        财务繁杂、人员成本、合同流失、不可控、坏账等；结算周期长、由上级代理发放；上级承诺不兑现、对账繁杂等；数据查取麻烦、无统计功能等；单一产品，无法系统化分析数据；PC后台、信息收集和录入负责，效率低；无管控能力、不受控制等。
                                    </div>
                                </div>
                            </div>
                        </div>
                        <copyright class="business-copy"></copyright>
                    </swiper-slide>
                </swiper>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-v" slot="pagination"></div>
        </swiper>

    </div>
</template>

<script>
    import NavBar from "../../components/navBar";
    import MoveNavBar from "../../components/moveNavBar";
    import Copyright from "../../components/copyright";

    export default {
        components: {
            Copyright,
            MoveNavBar,
            NavBar
        },
        name: "index",
        data() {
            let that = this;
            return {
                activeindex: 0,
                swiperOption: {
                    loopAdditionalSlides: 3,
                    direction: 'vertical',
                    mousewheel: true,
                    pagination: {
                        el: '.swiper-pagination-v',
                    },
                    on: {
                        transitionEnd: function (swiper) {
                            that.activeindex = this.activeIndex
                        }
                    }
                },
                swiperOption2: {
                    direction: 'vertical',
                    nested: true,
                    slidesPerView: 'auto',
                    freeMode: true,
                    scrollbar: { el: '.swiper-scrollbar' }
                }
            }
        },
        created() {
            // this.getInfoData()
        },
        mounted() {

        },
        methods: {
            //用户信息及签到状态
            async getInfoData() {
                this.info = await Service.getInfo()
                if (!this.info.id) {
                    this.$router.push({name: 'open'})
                } else {
                    this.showpage = true
                }
                if (this.info.signIn.length < 7) {
                    let num = 7 - this.info.signIn.length
                    for (var i = 0; i < num; i++) {
                        this.info.signIn.push(0)
                    }
                }
                // this.$toast({
                //     message: '自定义图标',
                //     icon: 'like-o'
                // });
            },
        }
    }
</script>

<style scoped lang="less">
    .my-swiper{
        width: 100%;
        height: 100vh;
    }
    .swiper-container {
        width: 100%;
        height: 100%;
    }
    .business-copy .each-item{
        height: auto!important;
    }
    .slide-item .image-wrap img{
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .slide-item .wrap{
        position: absolute;
        width: 400px;
        padding: 0 30px;
        .wrap-inner{
            font-size: 20px;
            color:#fff;
            line-height: 30px;
            .line{
                width: 55px;
                height: 3px;
                margin: 15px 0 30px;
                background-color: #fff;
            }
        }
    }
    .slide-item1 .wrap{
        top:40%;
        right:10%;
        transform: translate3d(-150%,0,0);
        transition: all 0.6s ease-in-out;
        opacity: 0;
    }
    .slide-item2 .wrap{
        top:40%;
        left:10%;
        transform: translate3d(-150%,0,0);
        transition: all 0.6s ease-in-out;
        opacity: 0;
    }
    .slide-item3 .wrap{
        top:40%;
        right:10%;
        transform: translate3d(-150%,0,0);
        transition: all 0.6s ease-in-out;
        opacity: 0;
        .wrap-inner{
            .line{
                background-color: #333;
            }
            color: #333;
        }
    }
    .slide-item .wrap-active{
        transform: none;
        opacity: 1;

    }
    #swiper-container2 .swiper-slide {
        height: auto;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display:block;
        background: #4390EE;
        color: #fff;
        .slide-item3-1{
            position: relative;
            height: 100vh;
        }
    }

    //手机端
    @media (max-width: 768px) {
        .slide-item{
            .image-wrap {
                img{
                    left: -130%;
                    width: auto;
                    height: 100%;
                }
            }
            .wrap{
                width: 80%;
                top:40%;
                right:0;
                left: 0;
                margin:0 auto;
                .wrap-inner{
                    font-size: 14px;
                }
            }
        }

    }

    //ipad端
    @media (max-width: 1025px) {
        .slide-item .image-wrap img{
            left: -50%;
            width: auto;
            height: 100%;
        }
    }

    /*下一页箭头样式*/
    .arrows{
        position: absolute;
        width: 80px;
        height: 80px;
        bottom: 30px;
        left:0;
        right:0;
        margin: 0 auto;
        color:#fff;
        cursor: pointer;
        text-align: center;
        .iconfont{
            position: absolute;
            left:0;
            right:0;
            margin: 0 auto;
            font-size: 30px;
            animation:downico 1.5s ease-out infinite;
        }
    }
    @keyframes downico {
        0% {
            top: 0;
            opacity: 1;
        }
        35% {
            top: 50%;
            opacity: 0;
        }
        65% {
            top: -50%;
            opacity: 0;
        }
        100% {
            top: 0;
            opacity: 1;
        }
    }
</style>