<template>
    <div class="ourservices">
        <div class="my-title">
            <van-divider>合作平台</van-divider>
            <div class="my-sub-title">Our Customers</div>
        </div>
        <div class="customers-list">
            <div class="cus-item" v-for="(item,index) in list" :key="index">
                <div class="dot lt-dot"></div>
                <div class="dot lb-dot"></div>
                <div class="dot rt-dot"></div>
                <div class="dot rb-dot"></div>
                <div class="item-inner">
                    <a>
                        <img :src="item.imgurl" />
                        <img :src="item.imgurla"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "our-customers",
        data(){
            return{
                list:[
                    {
                        imgurl:require('../../assets/images/1@2x.png'),
                        imgurla:require('../../assets/images/1-1@2x.png'),
                        name:'快钱'
                    },
                    {
                        imgurl:require('../../assets/images/2@2x.png'),
                        imgurla:require('../../assets/images/2-1@2x.png'),
                        name:'汇付天下'
                    },{
                        imgurl:require('../../assets/images/3@2x.png'),
                        imgurla:require('../../assets/images/3-1@2x.png'),
                        name:'海科融通'
                    },{
                        imgurl:require('../../assets/images/4@2x.png'),
                        imgurla:require('../../assets/images/4-1@2x.png'),
                        name:'畅捷支付'
                    },{
                        imgurl:require('../../assets/images/5@2x.png'),
                        imgurla:require('../../assets/images/5-1@2x.png'),
                        name:'瑞银信'
                    },{
                        imgurl:require('../../assets/images/6@2x.png'),
                        imgurla:require('../../assets/images/6-1@2x.png'),
                        name:'现代金控'
                    },{
                        imgurl:require('../../assets/images/7@2x.png'),
                        imgurla:require('../../assets/images/7-1@2x.png'),
                        name:'拉卡拉'
                    },{
                        imgurl:require('../../assets/images/8@2x.png'),
                        imgurla:require('../../assets/images/8-1@2x.png'),
                        name:'和融通'
                    },{
                        imgurl:require('../../assets/images/9@2x.png'),
                        imgurla:require('../../assets/images/9-1@2x.png'),
                        name:'开店宝'
                    },{
                        imgurl:require('../../assets/images/10@2x.png'),
                        imgurla:require('../../assets/images/10-1@2x.png'),
                        name:'联动优势'
                    }
                ]
            }
        }
    }
</script>

<style scoped lang="less">
    .ourservices {
        padding: 70px 0 40px;
        .customers-list{
            padding: 0 10% 80px;
            box-sizing: border-box;
            display: flex;
            flex-wrap: wrap;
            cursor: pointer;
            .cus-item{
                position: relative;
                width:20%;
                height: 200px;
                padding: 27px 0;
                box-sizing: border-box;
                img:first-child{
                    opacity:0;
                    transform:rotateY(180deg);
                }
                img:last-child{opacity: .6;}
                .item-inner{
                    width: 100%;
                    height: 145px;
                    border-right: 1px solid #e6e6e6;
                    a{
                        position: absolute;
                        top: 0;
                        left: 50%;
                        display: block;
                        width: 80%;
                        height: 200px;
                        margin-left: -40%;
                        border-bottom: 1px solid #e6e6e6;
                    }
                    img{
                        position: absolute;
                        width: 100%;
                        top: 0;
                        left: 0;
                        right:0;
                        bottom:0;
                        display: block;
                        margin:auto;
                        transition: all .5s linear 10ms;
                    }
                }
                .dot{
                    position: absolute;
                    width: 2px;
                    height: 2px;
                    background-color: #e6e6e6;
                }
                .lt-dot{
                    left: 0;
                    top: 0;
                }
                .lb-dot{
                    left: 0;
                    bottom: 0;
                }
                .rt-dot{
                    right: 0;
                    top: 0;
                }
                .rb-dot{
                    right: 0;
                    bottom: 0;
                }

            }
            .cus-item:hover img:first-child{
                opacity:1;
                transform:rotateY(0deg);
            }
            .cus-item:hover img:last-child{
                opacity:0;
                transform:rotateY(180deg);
            }
        }
    }
    @media (max-width:1330px){
        .ourservices {
            .customers-list{
                padding: 0 4.5% 80px;
            }
        }
    }
    @media (min-width:992px){
        .cus-item:nth-child(5n) .item-inner{
            border-right: none;
        }
    }
    @media (max-width:991px){
        .ourservices {
            padding: 40px 0 20px;
            .customers-list{
                .cus-item{
                    width: 50%;
                }
                .cus-item:nth-child(2n) .item-inner{
                    border-right: none;
                }
            }
        }
    }
</style>