<template>
    <div>
        <!--pc顶部导航-->
        <nav-bar class="pc-nav-hidden" :class="activeindex != 0?'nav-active':''"></nav-bar>
        <!--移动端导航-->
        <move-nav-bar class="move-nav-hidden"></move-nav-bar>
        <!--联系我们-->
        <!-- <contant class="sm-hidden" :showcontant="showcontant"></contant> -->
        <!--banner-->
        <top-banner></top-banner>
        <!--公司信息-->
        <company-info></company-info>
        <!--版权栏-->
        <copyright></copyright>
    </div>
</template>

<script>
    import NavBar from "../../components/navBar";
    import MoveNavBar from "../../components/moveNavBar";
    import Contant from "../../components/contant";
    import Copyright from "../../components/copyright";
    import TopBanner from "./topBanner";
    import CompanyInfo from "./companyInfo";

    export default {
        components: {
            CompanyInfo,
            TopBanner,
            Copyright,
            Contant,
            MoveNavBar,
            NavBar
        },
        name: "index",
        data() {
            let that = this;
            return {
                activeindex: 0,
                showcontant: false,
            }
        },
        created() {
            // this.getInfoData()
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    this.fullHeight = window.fullHeight
                })()
            }
            window.addEventListener('scroll', this.handleScroll, true)
        },
        methods: {
            handletop() {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            },
            handleScroll(e) {
                var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
                if (scrollTop > 120) {
                    this.activeindex = -1
                } else if (scrollTop < 50) {
                    this.activeindex = 0
                }
                if (scrollTop > 300) {
                    this.showcontant = true
                } else if (scrollTop < 150) {
                    this.showcontant = false
                }
            }

        }
    }
</script>

<style scoped lang="less">

</style>