<template>
    <div class="ourservices workSwiper">
        <div class="my-title">
            <van-divider>产品展示</van-divider>
            <div class="my-sub-title">Our Works</div>
        </div>
        <swiper :options="swiperOptionworks">
            <swiper-slide class="service-slide service-first">
                <div class="image-wrap">
                    <div class="image-inner sm-hidden1" :style="{backgroundImage: 'url(' + service1 + ')'}"></div>
                    <div class="image-inner lg-hidden1" :style="{backgroundImage: 'url(' + service1a + ')'}"></div>
                </div>
                <div class="service-container sm-hidden1">
                    <div class="service-title">联盟伙伴</div>
                    <div class="service-line"></div>
                    <div>
                        每位盟友都与总部直接签约，“新战略/新产品/新活动/新技能”培训直达每位盟友；价格统一、规则统一、透明、公平、公正、公开的制度，让盟友机会均等，按劳所得，多劳多得；盟友收益由平台直接发放，无中间环节，保障每个人的利益；盟友的业务拓展自由，随时随地开展，不受朝九晚五限制，不用被地域束缚，全国发展，组建团队，聚揽全国财富；不同于实物销售一次性收益，销售金融类产品的收益具有叠加性和永久性
                    </div>
                </div>
                <div class="lg-service-title lg-hidden1">联盟伙伴</div>
            </swiper-slide>
            <swiper-slide class="service-slide service-second">
                <div class="image-wrap">
                    <div class="image-inner sm-hidden1" :style="{backgroundImage: 'url(' + service2 + ')'}"></div>
                    <div class="image-inner lg-hidden1" :style="{backgroundImage: 'url(' + service2a + ')'}"></div>
                </div>
                <div class="service-container sm-hidden1">
                    <div class="service-title">联盟PRO</div>
                    <div class="service-line"></div>
                    <div>联盟Pro是一款划拨灵活，多维度数据统计，支持多品牌共存，多元化记账，账务清晰明了，并且具备多种奖励模式，交易类型名称可自定义的产品</div>
                </div>
                <div class="lg-service-title lg-hidden1"> 联盟PRO</div>
            </swiper-slide>
            <swiper-slide class="service-slide service-third">
                <div class="image-wrap">
                    <div class="image-inner sm-hidden1" :style="{backgroundImage: 'url(' + service3 + ')'}"></div>
                    <div class="image-inner lg-hidden1" :style="{backgroundImage: 'url(' + service3a + ')'}"></div>
                </div>
                <div class="service-container sm-hidden1">
                    <div class="service-title">1.0系统</div>
                    <div class="service-line"></div>
                    <div>
                        1.0系统是一款主要为了解决传统模式下对账难、对账复杂、赖账等账务问题，且系统支持多种机具划拨方式（列表、批量、制表）、多品牌共存且各品牌独立开展业务、也可根据自己的业务定义交易类型名称的产品
                    </div>
                </div>
                <div class="lg-service-title lg-hidden1">1.0系统</div>
            </swiper-slide>
            <div class="swiper-pagination swiper-pagination-h" slot="pagination"></div>
        </swiper>
        <!--<div class="swiper-button-prev swiper-button"></div>-->
        <!--<div class="swiper-button-next swiper-button"></div>-->

    </div>
</template>

<script>
    export default {
        name: "our-works",
        data() {
            let that = this;
            return {
                service1: require('../../assets/images/product-1.jpg'),
                service2: require('../../assets/images/product-2.jpg'),
                service3: require('../../assets/images/product-3.jpg'),
                service1a: require('../../assets/images/product-1a.jpg'),
                service2a: require('../../assets/images/product-2a.jpg'),
                service3a: require('../../assets/images/product-3a.jpg'),
                activeservice: 0,
                swiperOptionworks: {
                    navigation: {
                        prevEl: '.workSwiper .swiper-button-next',
                        nextEl: '.workSwiper .swiper-button-prev'
                    },
                    pagination: {
                        el: '.swiper-pagination-h',
                    },
                    autoplay:true,
                    delay:8000,
                    on: {
                        transitionEnd: function (swiper) {
                            console.log(this.activeIndex)
                            that.activeservice = this.activeIndex
                        }
                    }
                }
            }
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    /*行业痛点*/
    .ourservices {
        position: relative;
        margin-top: 40px;
        .my-title {
            position: absolute;
            top: 30px;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 100;
        }
        .swiper-container {
            background-color: #f0eeef;
            height: 800px;
            .image-wrap {
                overflow-x: hidden;
                position: absolute;
                width: 100%;
                height: 100%;
                .image-inner {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: auto 100%;
                }
            }
        }
        .service-container {
            position: absolute;
            width: 300px;
            height: 470px;
            top: 25%;
            right: 10%;
            z-index: 100;
            color: #333;
            background-color: #fff;
            padding: 50px 30px 30px 30px;
            box-sizing: border-box;
            text-align: center;
            .service-title {
                font-size: 20px;
                margin-bottom: 10px;
            }
            .service-line{
                width: 24px;
                margin: 15px auto 20px ;
                height: 3px;
                background-color: #999;
            }
        }
        .lg-service-title{
            position: absolute;
            left: 0;
            right: 0;
            width: 30%;
            margin: 0 auto;
            text-align: center;
            bottom: 21%;
            font-size: 1.3rem;
        }
    }

    /*行业痛点轮播图大小*/
    @media (min-width: 1330px ) {
        .ourservices {
            .swiper-container {
                width: 100%;
            }
        }

    }

    @media (max-width: 1229px ) {
        .ourservices {
            .swiper-container{
                height: 600px;
            }
            .service-container {
                height: 400px;
                top: 22%;
                right: 7%;
            }
        }
    }

    @media (max-width: 991px) {
        .ourservices {
            padding: 20px 0 20px;
            .swiper-container{
                height: 550px;
            }
        }

    }
</style>