<template>
    <div>
        <!--pc顶部导航-->
        <nav-bar class="pc-nav-hidden" :class="activeindex != 0?'nav-active':''"></nav-bar>
        <!--移动端导航-->
        <move-nav-bar class="move-nav-hidden"></move-nav-bar>
        <!--联系我们-->
        <contant class="sm-hidden" :showcontant="showcontant"></contant>
        <!--新闻资讯-->
        <div class="container">
            <div class="banner-warp">
               <img  :src="bannerurl"/>
            </div>
            <div class="newlist">
                <!--pc-tab导航-->
                <div class="pc-nav-hidden">
                    <div class="news-tab-list ">
                        <div class="news-nav-item" v-for="(item,index) in navlist" :key="index" :class="activeTab == index?'news-nav-item-active':''" @click="changetab(index)">{{item.name}}</div>
                    </div>
                </div>

                <!--move-tab导航-->
                <div class="move-nav-hidden">
                    <div class="news-tab-list2 ">
                        <div class="news-nav-item2" v-for="(item,index) in navlist" :key="index" :class="activeTab == index?'news-nav-item-active2':''" @click="changetab(index)">{{item.name}}</div>
                    </div>
                </div>

                <div class="news-list">
                    <div class="news-item" v-for="(item,index) in newslist" :key="index">
                        <div class="item-inner" >
                            <div class="deta-wrap">
                                <div class="bg-deta">{{item.months}}</div>
                                <div class="line"></div>
                                <div class="small-deta">{{item.year}}</div>
                            </div>
                            <div class="text-wrap">
                                <div class="title">{{item.title}}</div>
                                <div class="des">{{item.des}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--版权栏-->
        <copyright></copyright>
    </div>
</template>

<script>
    import NavBar from "../../components/navBar";
    import MoveNavBar from "../../components/moveNavBar";
    import Copyright from "../../components/copyright";
    import Contant from "../../components/contant";

    export default {
        components: {
            Contant,
            Copyright,
            MoveNavBar,
            NavBar},
        name: "index",
        data(){
            return{
                activeindex: 0,
                activeTab:0,
                showcontant: false,
                bannerurl:require('../../assets/images/news-banner.jpg'),
                navlist:[
                    {id:-1,name:'所有'},
                    {id:1,name:'公司新闻'}
                ],
                newslist:[],
                list:[
                    {
                        months:"23",
                        year:"2019.11",
                        title:"黑马新支付俱乐部",
                        des:"2019年11月23、24日山西麦古商务服务有限公司组织了首届黑马新支付俱乐部峰会，业内近50名企业家参与了本次会议，并分享了成功经验",
                        type:'0'
                    },
                    {
                        months:"09",
                        year:"2020.01",
                        title:"企业赞助",
                        des:"山西麦古商务服务有限公司赞助了第三届陕西支付圈行业年会，公司领导在会上发表了致辞",
                        type:'1'
                    },
                    {
                        months:"20",
                        year:"2019.09",
                        title:"企业福利",
                        des:"在山西麦古商务服务有限公司建立以来公司每逢佳节均会对企业员工进行人文关怀，发放与节日相关的礼品，充分体现了企业对员工的重视程度",
                        type:'1'
                    },
                    {
                        months:"15",
                        year:"2019.10",
                        title:"百日誓师大会",
                        des:"2019年10月15日距离2019年春节100天之时，公司组织了百日誓师大会，在会上公司领导发表了对公司前景的展望，并带领公司全体员工进行了宣誓，使公司凝聚力上升到了一个新的层次",
                        type:'1'
                    }
                ]
            }
        },
        created(){
            this.newslist= [...this.list];
        },
        methods:{
            changetab(index){
                this.activeTab = index
                if(index == 1){
                    this.newslist.splice(0,1)
                }else{
                    this.newslist= [...this.list];
                }
            },
            handleScroll(e) {
                var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
                if (scrollTop > 120) {
                    this.activeindex = -1
                } else if (scrollTop < 50) {
                    this.activeindex = 0
                }
                if (scrollTop > 300) {
                    this.showcontant = true
                } else if (scrollTop < 150) {
                    this.showcontant = false
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll, true)
        },

    }
</script>

<style scoped lang="less">
.container{
    background-color: #f6f6f6;
    padding-bottom: 80px;
    .banner-warp{
        img{
            width: 100%;
            min-height: 415px;
        }
    }
    .newlist{
        width: 90%;
        margin: 0 auto;
        .news-tab-list{
            padding:65px 0 55px ;
            display: flex;
            flex-wrap: wrap;
            .news-nav-item{
                width: 150px;
                height:50px;
                font-size: 14px;
                line-height: 50px;
                text-align: center;
                margin: 0 30px 20px 0;
                border-radius: 25px;
                transition: all .4s ease-in-out;
                cursor: pointer;
                background-color: #fff;
            }
            .news-nav-item:hover,
            .news-nav-item-active{
                color:#fff;
                background-color: #333;
            }
        }
        .news-tab-list2{
            display: flex;
            align-items: center;
            padding: 1rem 0;
            .news-nav-item2{
                width: auto;
                height: 1.8rem;
                line-height: 1.8rem;
                margin: 0 0.8rem 0.5rem 0;
                position: relative;
            }
            .news-nav-item2:after{
                content:'';
                bottom:0;
                position:absolute;
                display:block;
                width: 0;
                height:2px;
                background:#333;
                transition:all .4s;
            }
            .news-nav-item-active2:after{
                width: 100%;
            }
        }
        .news-list{
            display: flex;
            flex-wrap: wrap;
            .news-item{
                width: 49%;
                height: 220px;
                position: relative;
                background-color: #fff;
                margin: 0 2% 2% 0;
                cursor: pointer;
                .item-inner {
                    position: absolute;
                    height: 100%;
                    z-index: 1;
                    padding: 30px;
                    box-sizing: border-box;
                    display: flex;
                    .deta-wrap {
                        border-right: 1px solid #e7e7e7;
                        width: 80px;
                        min-width: 80px;
                        padding: 20px 0;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .bg-deta {
                            font-size: 36px;
                            font-weight: bold;
                            color: #999;
                        }
                        .line {
                            width: 20px;
                            height: 2px;
                            margin: 15px 0;
                            background-color: #e7e7e7;
                        }
                        .small-deta {
                            font-size: 12px;
                            color: #666;
                        }
                    }
                    .text-wrap {
                        flex: auto;
                        overflow-y: auto;
                        padding-left: 20px;
                        .title {
                            margin-top: -7px;
                            height: 40px;
                            line-height: 32px;
                            font-size: 18px;
                            overflow: hidden;
                        }
                        .des {
                            font-size: 12px;
                        }
                    }
                }
            }
            .news-item:nth-child(2n){
                margin: 0 0 2% 0;
            }
            .news-item:after {
                position: absolute;
                left: 0;
                top: 0;
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background: #fff;
                transition: all .6s;
            }
            .news-item:hover:after {
                transform: scale(1.025);
                box-shadow: 0 15px 15px rgba(0, 0, 0, .1);
            }
        }
    }
}
    @media(max-width: 991px){
        .container{
            .banner-warp{
                img{
                    width: 100%;
                    min-height: 15rem;
                }
            }
            .newlist{
                .news-list{
                    .news-item{
                        width: 100%;
                        height: 150px;
                        margin-right: 0;
                        margin-bottom: 0.6rem;
                        .item-inner{
                            .deta-wrap{
                                padding: 0;
                                .line{
                                    margin: 0;
                                }
                            }
                        }
                    }
                    .news-item:nth-child(2n){
                        margin-right: 0;
                        margin-bottom: 0.6rem;
                    }
                }
            }
        }
    }
</style>