<template>
    <div>
        <div class="move-nav">
            <div class="move-nav-left">
                <img  src="../assets/images/top-logo1.png"/>
            </div>
            <div class="move-nav-right" @click="show = true">
                <div class="sub-line"></div>
                <div class="sub-line"></div>
                <div class="sub-line"></div>
            </div>
            <van-popup v-model="show" closeable position="right" @opened="handleopen" @close="handleclose" :style="{ width: '70%',height:'100%' }">
                 <div class="menu-list" :class="showactive?'active':''">
                     <div class="menu-item" v-for="(item,index) in navlist" :key="index" @click="gopage(item.url)">{{item.name}}</div>
                 </div>
                 <div class="contant">
                     <div>资讯热线</div>
                     <a href="tel:177 1004 9798">177 1004 9798</a>
                 </div>
            </van-popup>
        </div>
    </div>
</template>

<script>
    export default {
        name: "move-nav-bar",
        data(){
            return{
                show:false,
                showactive:false,
                navlist:[
                    {url:'/',name:"首页" },
                    {url:'/about',name:"关于山西麦古商务服务有限公司"},
                    {url:'/business',name:"行业痛点"},
                    // {url:'/news',name:"新闻资讯"},
                    // {url:'/contant',name:"联系我们"}
                ]
            }
        },
        methods:{
            handleopen(){
                this.showactive = true
            },
            handleclose(){
                this.showactive = false
            },
            gopage(url){
                if(url){
                    if(this.$route.path == url){
                        return ''
                    }else{
                        this.$router.push({path:url})
                    }
                }

            },
        }
    }
</script>

<style scoped lang="less">
    .move-nav {
        position: fixed;
        top:0;
        width: 100%;
        background-color: #fff;
        height: 3.8rem;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.5rem;
        box-sizing: border-box;
        .move-nav-left {
           img{
               height: 2.6rem;
           }
        }
        .move-nav-right{
            width: 1.8rem;
            height: 1rem;
            display: flex;
            flex-direction: column;
           justify-content: space-between;
            .sub-line{
                width: 1.5rem;
                height:2px;
                border-radius: 1.5px;
                background-color: #333;
            }
        }
    }
    .van-popup{
        background-color: #000;
    }
    .menu-list{
        padding-left: 20%;
        margin-top: 5rem;
        .menu-item{
            width:330px;
            height:56px;
            line-height:55px;
            font-size: 18px;
            color: #fff;
            position:relative;
            overflow:hidden;
            opacity: 0;
            transform: translateY(50px);
            -webkit-transform: translateY(50px);
            -moz-transform: translateY(50px);
            transition: all 0s ease-in-out 0.3s;
            -webkit-transition: all 0s ease-in-out 0.3s;
            -moz-transition: all 0s ease-in-out 0.3s
        }
        .menu-item:nth-child(1){
            transition: all 0.3s ease-in-out 0.3s;
            -webkit-transition: all 0.3s ease-in-out 0.3s;
            -moz-transition: all 0.3s ease-in-out 0.3s;
        }
        .menu-item:nth-child(2){
            transition: all 0.3s ease-in-out 0.4s;
            -webkit-transition: all 0.3s ease-in-out 0.4s;
            -moz-transition: all 0.3s ease-in-out 0.4s;
        }
        .menu-item:nth-child(3){
            transition: all 0.3s ease-in-out 0.5s;
            -webkit-transition: all 0.3s ease-in-out 0.5s;
            -moz-transition: all 0.3s ease-in-out 0.5s;
        }
        .menu-item:nth-child(4){
            transition: all 0.3s ease-in-out 0.6s;
            -webkit-transition: all 0.3s ease-in-out 0.6s;
            -moz-transition: all 0.3s ease-in-out 0.6s;
        }
        .menu-item:nth-child(5){
            transition: all 0.3s ease-in-out 0.7s;
            -webkit-transition: all 0.3s ease-in-out 0.7s;
            -moz-transition: all 0.3s ease-in-out 0.7s;
        }
        .menu-item:nth-child(6){
            transition: all 0.3s ease-in-out 0.8s;
            -webkit-transition: all 0.3s ease-in-out 0.8s;
            -moz-transition: all 0.3s ease-in-out 0.8s;
        }
    }
    .active .menu-item{
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        opacity: 1;
    }
    .contant{
        position: absolute;
        bottom:5%;
        left: 20%;
        font-size: 18px;
        div{color:#ccc;font-size: 14px;}
        a{color: #fff;}

    }
</style>