<template>
    <div class="inner-banner" :style="{height:+ fullHeight +'px',backgroundImage: 'url(' + bgimg + ')'}" >
        <div class="banner-inner-warp">
            <div class="data-list">
                <div class="data-item data-item1">
                    <div><img src="../../assets/images/about-icon1-1a.png"/></div>
                    <div>一键操作</div>
                </div>
                <div class="data-item data-item1" >
                    <div><img src="../../assets/images/about-icon1-2a.png"/></div>
                    <div>标准化产品</div>
                </div>
                <div class="data-item data-item1" >
                    <div><img src="../../assets/images/about-icon1-3a.png"/></div>
                    <div>资深团队</div>
                </div>
                <div class="data-item " >
                    <div><img src="../../assets/images/about-icon1-4a.png"/></div>
                    <div>营销功能</div>
                </div>
                <div class="data-item " >
                    <div><img src="../../assets/images/about-icon1-5a.png"/></div>
                    <div>增值服务</div>
                </div>
                <div class="data-item " >
                    <div><img src="../../assets/images/about-icon1-6a.png"/></div>
                    <div>自定义开发</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "top-banner",
        data(){
            return{
                fullHeight: document.documentElement.clientHeight,
                bgimg:require('../../assets/images/banner_about.jpg'),
            }
        },
        mounted() {
            window.onresize = () => {
                return (() => {
                    window.fullHeight = document.documentElement.clientHeight
                    this.fullHeight = window.fullHeight
                })()
            }
            window.addEventListener('scroll', this.handleScroll, true)
        },
    }
</script>

<style scoped lang="less">
    .inner-banner{
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        .banner-inner-warp{
            position:absolute;
            left:0;
            top:0;
            width:100%;
            height:100%;
            background: linear-gradient(to right, rgba(30,95,116,.6) 0%,rgba(23,9,49,.6) 100%);
            z-index: 1;
            .data-list{
                position: absolute;
                width: 100%;
                height: 100%;
                top:0;
                z-index: 10;
                padding: 0 15%;
                box-sizing: border-box;
                color:#fff;
                background-color: rgba(0,0,0,0.5);
                display:flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                .data-item{
                    width: 33.2%;
                    height:240px ;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    border-right:1px solid rgba(255,255,255,.2);
                    border-bottom:1px solid rgba(255,255,255,.2);
                    img{
                        width: 70px;
                        height: 70px;
                    }
                    div:nth-child(1){
                        font-size: 60px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    @media (min-width: 992px) {
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(3n){
            border-right:none;
        }
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(4),
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(5),
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(6){
            border-bottom:none;
        }
    }
    @media (max-width: 991px) {
        .inner-banner .banner-inner-warp .data-list{
            padding: 0 1.2rem;
        }
        .inner-banner .banner-inner-warp .data-list .data-item{
            width: 49.8%;
            height: 9rem;
            img{
                width: 3rem;
                height:3rem;
            }
            div:nth-child(1){
                font-size: 2.4rem;
            }
        }
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(2n){
            border-right: none;
        }
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(5),
        .inner-banner .banner-inner-warp .data-list .data-item:nth-child(6){
            border-bottom: none;
        }
    }
</style>