<template>
  <div class="nav">
    <div class="nav-inner">
      <div class="nav-left">
        <img width="68px" height="68px" src="../assets/images/top-logo.png" />
        <span style="margin-left: 10px;">山西麦古</span>
      </div>
      <div class="nav-right">
        <div
          class="nav-item"
          v-for="(item, index) in navlist"
          :key="index"
          @click="gopage(item.url)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'nav-bar',
    data() {
      return {
        navlist: [
          { url: '/', name: '首页' },
          { url: '/about', name: '关于山西麦古商务服务有限公司' },
          { url: '/business', name: '行业痛点' },
          //    {url:'/news',name:"新闻资讯"},
          // { url: '/contant', name: '联系我们' }
        ]
      };
    },
    methods: {
      gopage(url) {
        if (url) {
          if (this.$route.path == url) {
            return '';
          } else {
            this.$router.push({ path: url });
          }
        }
      }
    }
  };
</script>

<style scoped lang="less">
  /*导航 -start*/
  .nav {
    z-index: 99;
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    transition: all 0.55s cubic-bezier(0.55, 0.75, 0.6, 1) 0s;
    -webkit-transition: all 0.55s cubic-bezier(0.55, 0.75, 0.6, 1) 0s;
    -moz-transition: all 0.55s cubic-bezier(0.55, 0.75, 0.6, 1) 0s;
    .nav-inner {
      padding: 0 4.5%;
    }
  }
  .nav-active {
    top: -100px;
  }
  .nav-left {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    font-weight: 700;
    color: #f9c012;
  }
  .nav-right {
    float: right;
    height: 72px;
    line-height: 72px;
  }
  .nav-item {
    display: inline-block;
    color: #ddd;
    position: relative;
    font-size: 16px;
  }
  @media (min-width: 1201px) {
    .nav-item {
      margin: 0 30px;
    }
  }
  @media (max-width: 1200px) {
    .nav-item {
      margin: 0 20px;
    }
  }
  .nav-item:after {
    content: '';
    bottom: 15px;
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    background: #fff;
    transition: all 0.4s;
  }
  .nav-item:hover {
    cursor: pointer;
    color: #fff;
  }
  .nav-item:hover:after {
    width: 100%;
  }
  /*导航 -end*/
</style>
