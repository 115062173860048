<template>
    <div class="ourservices serviceSwiper">
        <div class="my-title">
            <van-divider>行业痛点</van-divider>
            <div class="my-sub-title">Our Services</div>
        </div>
        <swiper :options="swiperOptionS" ref="mySwiperS">
            <swiper-slide class="service-slide service-first">
                <div class="image-wrap">
                    <div class="image-inner">
                        <img class="sm-hidden" :src="service1"/>
                        <img class="lg-hidden" :src="service1a"/>
                    </div>
                </div>
                <div class="service-container" :class="activeservice == 0?'service-container-active':''">
                    <div>代理政策不透明</div>
                    <div>容易扣押分润</div>
                    <div>分润不透明，即使少发可能也不知道</div>
                    <div>传统招商困难</div>
                    <div>人员成本太高</div>
                </div>
            </swiper-slide>
            <swiper-slide class="service-slide service-second">
                <div class="image-wrap">
                    <div class="image-inner">
                        <img class="sm-hidden" :src="service2"/>
                        <img class="lg-hidden" :src="service2a"/>
                    </div>
                </div>
                <div class="service-container" style="color: #333" :class="activeservice == 1?'service-container-active':''">
                    <div>机具管理混乱</div>
                    <div>财务压力大</div>
                    <div>结算不透明</div>
                    <div>无法个性化开展业务</div>
                    <div>无法随时提现</div>
                    <div>团队管理混乱</div>
                </div>
            </swiper-slide>
            <swiper-slide class="service-slide service-third" >
                <div class="image-wrap">
                    <div class="image-inner">
                        <img class="sm-hidden" :src="service3"/>
                        <img class="lg-hidden" :src="service3a"/>
                    </div>
                </div>
                <div class="service-container" style="color: #333" :class="activeservice == 2?'service-container-active':''">
                    <div>财务繁杂、人员成本、合同流失、不可控、坏账等</div>
                    <div>结算周期长、由上级代理发放</div>
                    <div>上级承诺不兑现、对账繁杂等</div>
                    <div>数据查取麻烦、无统计功能等</div>
                    <div>单一产品，无法系统化分析数据</div>
                    <div>PC后台、信息收集和录入负责，效率低</div>
                    <div>无管控能力、不受控制等</div>
                </div>
            </swiper-slide>
        </swiper>
        <div class="swiper-button-prev swiper-button prev">
            <i class="iconfont icon-jiantou"></i>
        </div>
        <div class="swiper-button-next swiper-button next">
            <i class="iconfont icon-jiantou-copy1"></i>
        </div>

    </div>
</template>

<script>
    export default {
        name: "our-services",
        data() {
            let that = this;
            return {
                service1: require('../../assets/images/busine-1.jpg'),
                service2: require('../../assets/images/busine-2.jpg'),
                service3: require('../../assets/images/busine-3.png'),
                service1a: require('../../assets/images/index-service1-3A.jpg'),
                service2a: require('../../assets/images/index-service1-3A.jpg'),
                service3a: require('../../assets/images/index-service1-3A.jpg'),
                activeservice: 0,
                swiperOptionS: {
                    navigation: {
                        prevEl: '.serviceSwiper .swiper-button-next',
                        nextEl: '.serviceSwiper .swiper-button-prev'
                    },
                    autoplay:true,
                    delay:8000,
                    on: {
                        transitionEnd: function (swiper) {
                            console.log(this.activeIndex)
                            that.activeservice = this.activeIndex
                        }
                    }
                }
            }
        },
        methods: {}
    }
</script>

<style scoped lang="less">
    /*行业痛点*/
    .ourservices {
        padding: 70px 0 40px;
        .service-container {
            position: absolute;
            width: 40%;
            bottom:30%;
            left: 10%;
            z-index: 100;
            color: #fff;
            font-size: 18px;
            opacity: 0;
            transform: translate3d(150%,0,0);
            transition: all .6s;
            div {
                margin-bottom: 15px;
            }
        }
        .service-container-active{
            opacity: 1;
            transform: none;
            transition: all 1s;
            transition-delay: 0.2s;
        }
        .image-wrap {
            .image-inner {
                img {
                    width: 99.8%;
                }
            }
        }
    }

    .ourservices {
        .swiper-button {
            position: relative;
            right: 0;
            left: 0;
            z-index: 100;
            color: #fff;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            text-align: center;
            line-height: 50px;
            background-color: #fff;
            cursor: pointer;
            margin-right: 60px;
            float: right;
            overflow: hidden;
            .iconfont {
                font-size: 26px;
                color: #333;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 10;
                transition: all .5s ease-in-out;
            }
        }
        .swiper-button:after {
            position: absolute;
            left: 0;
            top: 0;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            -moz-border-radius: 100%;
            -webkit-border-radius: 100%;
            transition: all .5s ease-in-out;
            box-sizing: border-box;
            transform: scale(0);
            background-color: #339ec1;
        }
        .swiper-button:hover:after {
            transform: scale(1);
        }
        .swiper-button:hover .iconfont {
            color: #fff;
        }
        .swiper-button:focus {
            outline: 0;
        }
        .prev {
            margin-top: -100px;
        }
        .next {
            margin-top: -180px;
        }
    }

    /*行业痛点轮播图大小*/
    @media (min-width: 1330px ) {
        .ourservices {
            .swiper-container {
                width: 100%;
            }
        }

    }

    @media (max-width: 1229px ) {
        .ourservices {
            .swiper-container {
                width: 90%;
            }
            .swiper-button {
                margin-right: 90px;
            }
        }
    }

    @media (max-width: 991px) {
        .ourservices {
            padding: 40px 0 20px;
            .service-container {
                width: 70%;
                bottom: 1rem;
                left: 10%;
                font-size: 14px;
                height: 14rem;
                overflow-y: auto;
                display: flex;
                flex-direction: column;
               justify-content: flex-end;
                overflow-y: auto;
                div {
                    margin-bottom: 6px;
                }
            }
            .swiper-button {
                width: 2.6rem;
                height: 2.6rem;
                line-height: 2.6rem;
                margin-right: 60px;
            }
            .prev {
                margin-top: -5rem;
            }
            .next {
                margin-top: -8.5rem;
            }
        }
    }

    @media (max-width: 768px) {
        .ourservices {
            .swiper-button {
                margin-right: 40px;
            }
        }
    }


</style>