<template>
    <div class="data-container">
        <div class="image-wrap">
            <div class="image-inner sm-hidden" :style="{backgroundImage: 'url(' + bgurl + ')'}"></div>
            <div class="image-inner lg-hidden" :style="{backgroundImage: 'url(' + bgurla + ')'}"></div>
        </div>
        <div class="data-list">
            <div class="data-item data-item1">
                <div v-if="shownumber"><span v-countRunning :data-target='25'>0</span><span>+</span></div>
                <div v-if="!shownumber"><span>0</span><span>+</span></div>
                <div>全国覆盖省份</div>
            </div>
            <div class="data-item data-item1" >
                <div v-if="shownumber"><span v-countRunning :data-target='100'>0</span><span>+</span></div>
                <div v-if="!shownumber"><span>0</span><span>+</span></div>
                <div>服务企业</div>
            </div>
            <div class="data-item data-item1" >
                <div v-if="shownumber"><span v-countRunning :data-target='70'>0</span><span>+</span></div>
                <div v-if="!shownumber"><span>0</span><span>+</span></div>
                <div>合作品牌</div>
            </div>
            <div class="data-item " >
                <div v-if="shownumber"><span v-countRunning :data-target='50'>0</span><span>+</span></div>
                <div v-if="!shownumber"><span>0</span><span>+</span></div>
                <div>公司人数</div>
            </div>
            <div class="data-item " >
                <div v-if="shownumber"><span v-countRunning :data-target='200'>0</span><span class="unit">亿</span><span>+</span></div>
                <div v-if="!shownumber"><span>0</span><span class="unit">亿</span><span>+</span></div>
                <div>月交易量</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "data-number",
        props:{
            shownumber:{
                type:Boolean,
                default:false
            }
        },
        data(){
            return{
                bgurl:require('../../assets/images/index-data-bg-m.jpg'),
                bgurla:require('../../assets/images/index-data-bg-m.jpg')
            }
        },
    }
</script>

<style scoped lang="less">
    .data-container{
        position: relative;
        .image-wrap{
            height: 600px;
            .image-inner{
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
        .data-list{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0;
            z-index: 10;
            padding: 0 100px;
            box-sizing: border-box;
            color:#fff;
            background-color: rgba(0,0,0,0.5);
            display:flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            .data-item{
                width: 33.2%;
                height:240px ;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-right:1px solid rgba(255,255,255,.2);
                border-bottom:1px solid rgba(255,255,255,.2);
                div:nth-child(1){
                    font-size: 60px;
                    font-weight: bold;
                }
                .unit{
                    font-size: 24px;
                }
            }
        }
    }
    @media (max-width: 1220px) {
        .data-container .data-list .data-item{
            height: 150px;
        }
    }
    @media (min-width: 992px) {
        .data-container .image-wrap .image-inner{background-size: 100% 100%}
        .data-container .data-list .data-item:nth-child(3n){
            border-right:none;
        }
        .data-container .data-list .data-item:nth-child(4),
        .data-container .data-list .data-item:nth-child(5){
            border-bottom:none;
        }
    }
    @media (max-width: 991px) {
        .data-container{
            height: 600px;
        }
        .data-container .data-list{
            padding: 0 1.2rem;
        }
        .data-container .data-list .data-item{
            width: 49.8%;
            height: 180px;
            div:nth-child(1){
               font-size: 2.4rem;
            }
        }
        .data-container .data-list .data-item:nth-child(2n){
            border-right: none;
        }
        .data-container .data-list .data-item:nth-child(5){
            border-bottom: none;
        }
    }
</style>