<template>
    <div>
        <!--pc顶部导航-->
        <nav-bar class="pc-nav-hidden" :class="activeindex != 0?'nav-active':''"></nav-bar>
        <!--移动端导航-->
        <move-nav-bar class="move-nav-hidden"></move-nav-bar>
        <!--联系我们-->
        <contant class="sm-hidden" :showcontant="showcontant"></contant>
        <!--联系我们-->
        <div class="container">
            <div class="banner-warp">
                <img  :src="bannerurl"/>
            </div>
            <div class="map-wrap">
               <div class="title">地图导航</div>
                <div class="image-wrap">
                    <div class="image-inner">
                        <img :src="mapurl"/>
                    </div>
                    <!--<div id="container" ></div>-->
                </div>

                <div class="title">交通指引</div>
                <div class="traffic-wrap">
                    <div class="item">
                        <div class="item-title">公交车</div>
                        <div class="item-content">
                            <div>站台：四惠东站</div>
                            <div>线路：363路、397路、468路、475路、506路、517路、553路、666路、夜27路、专141路等</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="item-title">地铁</div>
                        <div class="item-content">
                            <div>站台：四惠东站(A口出)</div>
                            <div>线路：1号线、八通线</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--版权栏-->
        <copyright></copyright>
    </div>
</template>

<script>
    import NavBar from "../../components/navBar";
    import MoveNavBar from "../../components/moveNavBar";
    import Copyright from "../../components/copyright";
    import Contant from "../../components/contant";
    // import AMap from 'AMap' // 引入高德地图
    export default {
        components: {
            Contant,
            Copyright,
            MoveNavBar,
            NavBar},
        name: "index",
        data(){
            return{
                activeindex: 0,
                activeTab:0,
                showcontant: false,
                bannerurl:require('../../assets/images/contact-banner.png'),
                mapurl:require('../../assets/images/map.jpg'),
                input:'君天大厦'
            }
        },
        methods:{
            handleScroll(e) {
                var scrollTop = e.target.documentElement.scrollTop || e.target.body.scrollTop;
                if (scrollTop > 120) {
                    this.activeindex = -1
                } else if (scrollTop < 50) {
                    this.activeindex = 0
                }
                if (scrollTop > 300) {
                    this.showcontant = true
                } else if (scrollTop < 150) {
                    this.showcontant = false
                }
            },
            // init () {
            //     let map = new AMap.Map('container', {
            //         center: [116.5152,39.90432],
            //         resizeEnable: true,
            //         zoom: 50
            //     })
            //     var MSearch;
            //     var name = '君天大厦'
            //     AMap.plugin(['AMap.ToolBar', 'AMap.Scale','AMap.PlaceSearch'], function () {

            //         map.addControl(new AMap.ToolBar())
            //         map.addControl(new AMap.Scale())
            //         MSearch = new AMap.PlaceSearch({
            //             //构造地点查询类
            //             city: "" //城市
            //         });
            //         AMap.event.addListener(MSearch,"complete",this.keywordSearch_CallBack) //返回地点查询结果
            //         MSearch.search(name); //关键字查询
            //     })
            // },
            keywordSearch_CallBack(data) {
                console.log(data)
            },
            search() {
                this.mapObj=''
                this.mapInit()
                this.placeSearch(this.address)
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll, true)
            // this.init();
        },

    }
</script>

<style scoped lang="less">
    .container{
        background-color: #f6f6f6;
        padding-bottom: 80px;
        .banner-warp{
            img{
                width: 100%;
                min-height: 415px;
            }
        }
        .map-wrap{
            width: 90%;
            margin: 0 auto;
            background-color: #fff;
            .title{
                font-size: 28px;
                font-weight: bold;
                text-align: center;
                padding: 40px 0;
            }
            .image-wrap{
                position: relative;
                #container{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    bottom:0;
                    z-index: 100;
                }
            }

            img{
                width: 100%;
            }
            .traffic-wrap{
                display: flex;
                .item{
                   width: 50%;
                    min-height: 400px;
                    padding: 3% 10% 5% 7%;
                    box-sizing: border-box;
                    .item-title{
                        padding: 30px 0;
                        font-size: 20px;
                        color: #333;
                        margin-bottom: 20px;
                        font-weight: bold;
                        border-bottom: 1px solid #e5e5e5;
                    }
                }
                .item:nth-child(2){
                    background-color: #f1f1f1;
                }
            }
        }
    }
    @media(max-width: 991px){
        .container{
            .banner-warp{
                img{
                    width: 100%;
                    min-height: 15rem;
                }
            }
            .map-wrap{
                .title{
                    font-size: 20px;
                    padding: 30px 0;
                }
                .traffic-wrap{
                    flex-wrap: wrap;
                    .item{
                        width: 100%;
                        min-height: 300px;
                        .item-title{
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
</style>