import Vue from "vue";
import Vant from 'vant';
import 'vant/lib/index.css';
import App from "./App.vue";
import VueLazyload from 'vue-lazyload'

import  './assets/iconfont/iconfont.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.use(Vant);
Vue.use(VueLazyload);

import  './untils/common'

Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: 'dist/error.png',
    loading: 'dist/loading.gif',
    attempt: 1
})

import "./registerServiceWorker";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;
console.log(process.env);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
