<template>
    <div class="about">
        <div class="container">
            <div class="about-wrap-first">
                <div class="inner-title">山西麦古商务服务有限公司</div>
                <div class="inner-line"></div>
                <div class="inner-des">公司成立于 2022年03月，是一家专注于支付技术研发的数据公司， 其研发人员总数达到了75%以上，公司在无卡支付、pos等支付领域不断深耕；
                    为数百家企业和支付公司提供了无卡支付系统，pos联盟3.0系统，pos分润秒结系统等。
                    公司一直秉承“求实、严谨、高效、创新、分享”的核心价值观，以成为最有价值的技术服务公司为愿景。不断提升核心竞争力，并在业内取得了非常不斐的成绩。
                </div>
            </div>
            <div class="about-wrap-second">
                <div class="each-item">
                   <div class="image-wrap">
                       <div class="image-inner">
                           <img :src="about1"/>
                       </div>
                   </div>
                    <div class="text-wrap">
                        <div class="text-inner">
                            <div class="text-title">企业愿景</div>
                            <div class="text-line"></div>
                            <div class="text-des">成为最有价值的技术服务公司</div>
                        </div>
                    </div>
                </div>
                <div class="each-item">
                    <div class="image-wrap">
                        <div class="image-inner">
                            <img :src="about2"/>
                        </div>
                    </div>
                    <div class="text-wrap">
                        <div class="text-inner">
                            <div class="text-title">企业使命</div>
                            <div class="text-line"></div>
                            <div class="text-des">用技术让社会更高效，生活更美好</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="about-wrap-third">
                <div class="each-item">
                    <div class="image-wrap">
                        <div class="image-inner">
                            <img class="sm-hidden" :src="about4"/>
                            <img class="lg-hidden" :src="about3"/>
                        </div>
                    </div>
                    <div class="text-wrap">
                        <div class="text-inner">
                            <div class="text-title">分享利他，携手共拓</div>
                            <div class="text-line"></div>
                            <div class="text-des">公司将继续在技术研发和数据安全等领域不断深耕，将合作伙伴的需求和利益放在第一位，助力合作伙伴成功，公司愿与员工，合作伙伴，社会一起分享梦想、分享事业、分享成功，与大家一起掌握航向破浪前行</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "company-info",
        data() {
            return {
                about1:require('../../assets/images/about-list1-1.png'),
                about2:require('../../assets/images/about-list1-2.png'),
                about3:require('../../assets/images/about-list2.jpg'),
                about4:require('../../assets/images/about-list3.jpg'),
            }
        }
    }
</script>

<style scoped lang="less">
    .about {
        background-color: #f6f6f6;
        .container {
            width: 80%;
            margin: 0 auto;
            padding-bottom: 50px;
            .about-wrap-first {
                z-index: 1;
                position: relative;
                text-align: center;
                background: #fff;
                transition: all .6s ease-in-out;
                margin-top: -80px;
                padding: 10%;
                margin-bottom: 1%;
                .inner-title{
                    font-size: 36px;
                    font-weight: bold;
                }
                .inner-line{
                    width: 55px;
                    height: 3px;
                    margin: 15px auto 30px;
                    background: #333;
                }
                .inner-des{
                    font-size: 16px;
                }
            }
            .about-wrap-second{
                display: flex;
                justify-content: space-between;
                margin-bottom: 1%;
                .each-item{
                    width: 49.5%;
                    position: relative;
                    cursor: pointer;
                    .image-wrap{
                        .image-inner{
                            img{width: 100%;}
                        }
                    }
                    .text-wrap{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top:0;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 1;
                        padding: 20px;
                        box-sizing: border-box;
                        opacity: 0;
                        transition: all 0.6s;
                        .text-inner{
                            color: #fff;
                            transform: translateY(50%);
                            transition: all 0.5s ease-out 0s;
                            position: absolute;
                            bottom:10px;
                            .text-title{
                                font-size: 20px;
                                font-weight: bold;
                            }
                            .text-line{
                                width: 55px;
                                height: 2px;
                                margin: 10px 0 5px;
                                background: #fff;
                            }
                            .text-des{
                                font-size: 14px;
                            }
                        }
                    }
                    .text-wrap:hover{
                        opacity: 1;
                    }
                    .text-wrap:hover .text-inner{
                        transform: translateY(-50%);
                    }
                }
            }
            .about-wrap-third{
                display: flex;
                justify-content: space-between;
                .each-item{
                    position: relative;
                    cursor: pointer;
                    margin: 0 auto;
                    .image-wrap{
                        .image-inner{
                            img{
                                width: 100%;
                            }
                        }
                    }
                    .text-wrap{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top:0;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 1;
                        padding: 20px;
                        box-sizing: border-box;
                        opacity: 0;
                        transition: all 0.6s;
                        .text-inner{
                            color: #fff;
                            transform: translateY(50%);
                            transition: all 0.5s ease-out 0s;
                            position: absolute;
                            bottom:10px;
                            .text-title{
                                font-size: 20px;
                                font-weight: bold;
                            }
                            .text-line{
                                width: 55px;
                                height: 2px;
                                margin: 10px 0 5px;
                                background: #fff;
                            }
                            .text-des{
                                font-size: 14px;
                            }
                        }
                    }
                    .text-wrap:hover{
                        opacity: 1;
                    }
                    .text-wrap:hover .text-inner{
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    @media (max-width: 1330px) {
        .about .container .about-wrap-first {
            margin-top: -50px;
        }
    }
    @media(max-width: 991px){
        .about{
            .container{
                width: 90%;
                .about-wrap-first {
                    margin-bottom: 2%;
                    .inner-title{
                        font-size: 32px;
                    }
                    .inner-line{
                        width: 55px;
                        height: 3px;
                        margin: 15px auto 30px;
                    }
                    .inner-des{
                        font-size: 16px;
                    }
                }
                .about-wrap-second{
                    flex-direction: column;
                    .each-item{
                        width: 100%;
                        margin-bottom: 2%;
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        .about{
            .container{
                .about-wrap-first {
                    .inner-title{
                        font-size: 20px;
                    }
                    .inner-line{
                        width: 30px;
                        height: 2px;
                        margin: 15px auto;
                    }
                    .inner-des{
                        font-size: 14px;
                    }
                }
                .about-wrap-third{
                    .each-item{
                        .image-wrap{
                            .image-inner{
                                img{
                                    width: 100%;
                                }
                            }
                        }
                        .text-wrap:hover .text-inner{
                            transform: translateY(-20%);
                        }
                    }
                }
            }
        }
    }

</style>